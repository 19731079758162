export const SET_ADDRESS = "SET_ADDRESS";
export const SET_COORDINATES = "SET_COORDINATES";

export const SET_SEARCHABLE = "SET_SEARCHABLE";
export const SET_UNSEARCHABLE = "SET_UNSEARCHABLE";
export const SET_RESULTS_DISPLAYED = "SET_RESULTS_DISPLAYED";

export const GET_CURRENT_WEATHER = "GET_CURRENT_WEATHER";
export const GET_CURRENT_WEATHER_CONDITIONS = "GET_CURRENT_WEATHER_CONDITIONS";
export const GET_CURRENT_WEATHER_DESCRIPTION =
  "GET_CURRENT_WEATHER_DESCRIPTION";
export const GET_CURRENT_TEMPERATURE = "GET_CURRENT_TEMPERATURE";
export const GET_CURRENT_HUMIDITY = "GET_CURRENT_HUMIDITY";
export const GET_LOCATION_INFO = "GET_LOCATION_INFO";
