import React from "react";

const NotFound = () => {
  return (
    <div className="text-center">
      <h1 className="pt-5 pb-2">Page Not Found.</h1>
      <p>Nothing here, please try another url...</p>
    </div>
  );
};

export default NotFound;
