import React from "react";

const About = () => {
  return (
    <div>
      <h1 className="text-center">GeoInfo.cc</h1>
      <h4 className="text-center">Find information about a location through API calls and React.</h4>
      <hr/>
      <p className="text-center">
        This project is a study in using React to perform API calls and delivering them to a light front-end.
      </p>
    </div>
  );
};

export default About;
